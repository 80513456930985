import { storeToRefs } from 'pinia'; // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/store/auth'; // import the auth store we just created

export default defineNuxtRouteMiddleware((to) => {

    const { authenticated, role } = storeToRefs(useAuthStore()); // make authenticated state reactive

    // Set layout depending on loggedin role
    if (authenticated && role.value === 'admin') {
        return setPageLayout('admin');
    }

    if (authenticated && role.value === 'client') {
        return setPageLayout('client')
    }

    if (authenticated && role.value === 'payspot') {
        return setPageLayout('payspot')
    }

    if (authenticated && role.value === 'scanner') {
        return setPageLayout('scanner')
    }

    return setPageLayout('default');

});
